/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
import Swiper from 'swiper/js/swiper.min';

import SplitText from "@lib/gsap-pro/SplitText";

import { gsap } from "gsap";
import CustomEase from 'gsap/CustomEase';

import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import LocomotiveScroll from 'locomotive-scroll';

gsap.registerPlugin(CustomEase);

import modularLoad from 'modularload';

/*
|
| Importing Components
|-----------------------
*/
import CookieManager from '@components/cookie-manager';

/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
	  {
      'file': main, 
      'dependencies': [app, modularLoad, gsap, CustomEase, LocomotiveScroll, Swiper, SplitText, ScrollTrigger, CookieManager]
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

