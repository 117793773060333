/*** IMPORTS FROM imports-loader ***/
var define = false;

import { Expo } from "gsap";
import { Power1 } from "gsap/gsap-core";
import { Power3 } from "gsap/gsap-core";

export default {
	init: (app, modularLoad, gsap, CustomEase, LocomotiveScroll, Swiper, SplitText, ScrollTrigger, CookieManager) => {

		/*
		|
		| Constants
		|-----------
		*/
        const
            $body         = $('body'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose  = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
        ;

        gsap.registerPlugin(ScrollTrigger);

        CustomEase.create("easeOutCustom", ".215,.61,.355,1");
        CustomEase.create("easeOut", ".165,.84,.44,1");
        

        /*
		|
		| Loader
		|-----------------
        */
        const loaderTl = new gsap.timeline();

        loaderTl.to('.loader__img', 0.8, { autoAlpha: 1, y: 0, ease: Power1.easeOut, onComplete: () => app.dispachEvent($body, 'loaderEnd')}, "start")
                .to('.loader', 1, {y: "-100%", ease: Expo.easeInOut}, "start+=0.5")

        $(window).on('load', function(){
            loaderTl.play();
        })

        /*
		|
		| Menu
		|-----------------
        */
        const menuTl = new gsap.timeline({paused: true});

        const $items = document.querySelectorAll('.nav__mobile-item');
        const $networkItems = document.querySelectorAll('.nav__mobile-network');

        menuTl.from('.nav__mobile', 1.4, { scaleY: 0, ease: Expo.easeInOut}, "start")
              .add(() => {
                $('.header__mobile').toggleClass('clicked')
              }, "-=0.5")
              .staggerFrom($items, 0.1, {autoAlpha: 0, y: 40, ease: Power1.easeOut}, 0.1, "-=0.6")
              .staggerFrom($networkItems, 0.1, {autoAlpha: 0, y: 40, ease: Power1.easeOut}, 0.1, "-=0.3")
              .from('.mobile__mention', 0.3, { y:20, autoAlpha:0, ease: Power1.easeOut}, "-=0.1")


        document.querySelector('.menu-mobile-btn').addEventListener('click', function(e){
            e.preventDefault();

            if (this.classList.contains('activated')) {
                menuTl.timeScale(1.3).reverse();
            } else {
                menuTl.play();
            }

            this.classList.toggle('activated');
        })

        /*
		|
		| Langues
		|-----------------
        */
        const langTl = new gsap.timeline({paused: true});

        langTl.set('.header__lang-sub', { display: "block" }, "start")
              .staggerFrom('.header__lang-sub li', 0.3, {autoAlpha: 0, y: 20, ease: Power1.easeOut}, 0.1, "start")
              .to('.header__lang-btn .icon svg', 0.3, {rotation: 180, ease: Power1.easeOut}, 'start')

        $('.header__lang-btn').on('click', function(e){

            e.preventDefault();

            if ($(this).hasClass('active')) {
                langTl.reverse();
                $(this).removeClass('active');
            } else {
                langTl.play();
                $(this).addClass('active');
            }

        })

        /*
		|
		| Split texts
		|-----------------
        */
        $('[data-split-lines]').each(function(){

            let $item = $(this);
            new SplitText($item, { type: 'lines, words', linesClass: 'item__line', wordsClass: 'item__words' })

            $item.find('.item__line').wrap('<div class="item__line-wrapper">');

        })

        //first section
        let lines = $('.title__section-title').find('.item__line');
        const tlFirst = new gsap.timeline({paused: true});
        tlFirst.staggerFrom(lines, 1.2, {y: '100%', ease: "easeOut"}, 0.2, 0);

        /*
        | Single
        |
        | single post page
        |------------
        */
        let tlSingleFirst = null;

        if ($('.single-post').length) {

            //first section
            tlSingleFirst = new gsap.timeline({paused: true});

            tlSingleFirst.from('.layer__header', 1.2, { scaleX: 0, ease: Expo.easeInOut }, 'start')
                        .from('.post__single-title', 0.6, { y:40, autoAlpha:0, ease: Power1.easeOut}, 'start+=0.8')
                        .from('.single__back', 0.6, { y:40, autoAlpha:0, ease: Power1.easeOut}, 'start+=0.8')

        }


        /*
		|
		| Smooth scroll
		|-----------------
        */
        let LocomotiveScrollTop = 0;
		let targetTop = 0;
        let offsetDrag = 0;
        let locoScroll;

        gsap.registerPlugin(ScrollTrigger);

        $body.on('loaderEnd', () => {

            // Using Locomotive Scroll from Locomotive https://github.com/locomotivemtl/locomotive-scroll

            let scroll = document.querySelector('.blog') ? false : true;

            locoScroll = new LocomotiveScroll({
                el: document.querySelector(".layout__scroll"),
                smooth: true,
                smoothMobile: scroll
            });

            locoScroll.on('call', (value, way, obj) => {
				if (value === "drag") {
					targetTop = obj.top;
				}
			});

            locoScroll.on('scroll', instance => {
                const scrollTop = instance.scroll.y
				LocomotiveScrollTop = scrollTop;
                offsetDrag = targetTop - instance.scroll.y;
                ScrollTrigger.update()
            });

            // tell ScrollTrigger to use these proxy methods for the ".layout__scroll" element since Locomotive Scroll is hijacking things
            ScrollTrigger.scrollerProxy(".layout__scroll", {
                scrollTop(value) {
                    return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
                }, // we don't have to define a scrollLeft because we're only scrolling vertically.
                getBoundingClientRect() {
                    return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
                },
                // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
                pinType: document.querySelector(".layout__scroll").style.transform ? "transform" : "fixed"
            });

            /*
            *
            * -------
            * animation yellow
            *  ------
            */

            if ($('#targetYellow').length > 0) {

                let titleMultiplication = document.querySelector('.multiplication__title-span');
                let dividerMultiplication = document.querySelector('.divider__line');

                //left
                let titleLeft = document.querySelector('.multiplication__left .title-1');
                let textLeft = document.querySelector('.multiplication__left .legend');

                //middle
                let titleCenter = document.querySelector('.multiplication__middle .title-1');

                //right
                let titleRight = document.querySelector('.multiplication__right .title-1');
                let textRight = document.querySelector('.multiplication__right .legend');

                let egalSymbol = document.querySelector('.flex-symbol');
                let operationResult = document.querySelector('.flex-result');

                let timelineYellow = gsap.timeline({
                    scrollTrigger: {
                        trigger: '#targetYellow',
                        scroller: ".layout__scroll",
                        scrub: true,
                        pin: true,
                        start: "top top",
                        end: "+=300%",
                        toggleActions: "play none reverse none",
                        onUpdate: self => {

                            $('.header').addClass('is__hidden');

                            if (self.progress === 1 | self.progress === 0) {
                                $('.header').removeClass('is__hidden');
                            }

                        }
                    }
                });

                timelineYellow.from('.layer__yellow', 5.6, {scale: 0, ease: Expo.easeInOut}, "start")
                            .from(titleMultiplication, 1.5, {y: "100%", autoAlpha:0, ease: "easeOut"}, "+=0")
                            .from(dividerMultiplication, 1.5, {scaleX:0, ease: "easeOut"}, "+=0")
                            .from(titleLeft, 1.5, {autoAlpha:0, y: 40, ease: "easeOut"}, "+=0")
                            .from(textLeft, 1.5, {autoAlpha:0, y: 40, ease: "easeOut"}, "+=0")
                            .from(titleCenter, 1.5, {autoAlpha:0, y: 40, ease: "easeOut"}, "+=0")
                            .from(titleRight, 1.5, {autoAlpha:0, y: 40, ease: "easeOut"}, "+=0")
                            .from(textRight, 1.5, {autoAlpha:0, y: 40, ease: "easeOut"}, "+=0")
                            .to(titleMultiplication, 0.5, {autoAlpha: 0, y: -40, ease: "easeOut"}, "+=0")
                            .to(dividerMultiplication, 0.5, {autoAlpha: 0, y: -40, ease: "easeOut"}, "+=0")
                            .to('.multiplication__left', 0.5, {autoAlpha: 0, y: -40, ease: "easeOut"}, "+=0")
                            .to('.multiplication__middle', 0.5, {autoAlpha: 0, y: -40, ease: "easeOut"}, "+=0")
                            .to('.multiplication__right', 0.5, {autoAlpha: 0, y: -40, ease: "easeOut"}, "+=0")
                            .from(egalSymbol, 1.5, {autoAlpha:0, y: 40, ease: "easeOut"}, "+=0")
                            .from(operationResult, 1.5, {autoAlpha:0, y: 40, ease: "easeOut",}, "+=0")

            }
            

            /*
            *
            * -------
            * Back to top
            *  ------
            */

            document.querySelectorAll('.back__top-btn').forEach(btn => {
                btn.addEventListener('click', function(e){
                    e.preventDefault();
                    locoScroll.scrollTo(0);
                });
            })

            /*
            *
            * -------
            * first
            *  ------
            */
            tlFirst.play();

            /*
            *
            * -------
            * Split lines
            *  ------
            */
            document.querySelectorAll('.a__text-lines').forEach((item, index) => {

                let lines = item.querySelectorAll('.item__line');
                let trigger = item.closest('section');
                let dataStart;
                let dataEnd;

                if ($(window).width() > 767) {
                    dataStart = item.dataset.start ? item.dataset.start : 'top-=90%';
                    dataEnd = item.dataset.end ? item.dataset.end : '+=100%';
                } else {
                    dataStart = 'top-=120%';
                    dataEnd = '+=10%';
                }

                let timeline = gsap.timeline({
                    scrollTrigger: {
                        trigger: trigger,
                        scroller: ".layout__scroll",
                        start: dataStart,
                        end: dataEnd
                    }
                });

                timeline.staggerFrom(lines, 0.6, {
                    y: '200%',
                    ease: "easeOut"
                }, 0.15);
            
            });

            document.querySelectorAll('.split__img').forEach((item, index) => {

                let trigger = item.closest('section');

                let dataStart = item.dataset.start ? item.dataset.start : 'top-=30%';
                let dataEnd = item.dataset.end ? item.dataset.end : '+=50%';

                let timeline = gsap.timeline({
                    scrollTrigger: {
                        trigger: trigger,
                        scroller: ".layout__scroll",
                        start: dataStart,
                        end: dataEnd
                    }
                });

                timeline.from(item, 1, {
                    autoAlpha: 0,
                    ease: Power1.easeOut
                }, 0.1);
            
            });

            /*
            *
            * -------
            * Black section
            *  ------
            */
            if ($(window).width() > 767 ) {
                document.querySelectorAll('.black__section').forEach((item, index) => {

                    let timeline = gsap.timeline({
                        scrollTrigger: {
                            trigger: item,
                            scroller: ".layout__scroll",
                            start: 'top-=40%',
                            end: 'bottom-=270',
                            toggleActions: 'play reverse play reverse'
                        }
                    });
    
                    timeline.to('body', 0.3, { backgroundColor: "#000000", ease: "easeOutCustom" }, 'start');
                    timeline.to('.header', 0.3, { autoAlpha: 0, ease: "easeOutCustom" }, 'start');
            
                });
            }
            

            /*
            *
            * -------
            * Brand item
            *  ------
            */
            document.querySelectorAll('.brand__item').forEach((item, index) => {

                let timeline = gsap.timeline({
                    scrollTrigger: {
                        trigger: item,
                        scroller: ".layout__scroll",
                        start: $(window).width > 767 ? 'top-=190%' : 'top-=250%'
                    }
                });

                let layer = item.querySelector('.brand__layer');
                let title = item.querySelector('.brand__title');
                let desc = item.querySelector('.brand__desc');

                timeline.from(layer, 1.2, { scaleX: 0, ease: Expo.easeInOut }, 'start');
                timeline.from(title, 0.6, { autoAlpha: 0, y: 40, ease: Power1.easeOut }, '-=0.5');
                timeline.from(desc, 0.6, { autoAlpha: 0, y: 40, ease: Power1.easeOut }, '-=0.3');
        
            });

             /*
            *
            * -------
            * Brand item
            *  ------
            */
            document.querySelectorAll('.a__stagger').forEach((item, index) => {

                let dataStart = item.dataset.start ? item.dataset.start : 'top-=30%';
                let dataEnd = item.dataset.end ? item.dataset.end : '+=50%';
                let trigger = item.dataset.trigger ? item.closest(item.dataset.trigger) : item;

                let timeline = gsap.timeline({
                    scrollTrigger: {
                        trigger: trigger,
                        scroller: ".layout__scroll",
                        start: dataStart
                    }
                });

                let items = item.querySelectorAll('.a__stagger-item').length > 0 ? item.querySelectorAll('.a__stagger-item') : item.querySelector('.a__stagger-item-p').querySelectorAll('p, li');

                timeline.staggerFrom(items, 1.2, { y: 100, autoAlpha:0, ease: "easeOut" }, 0.2, 'start');
        
            });

            /*
            *
            * -------
            * Footer
            *  ------
            */
            let footerEl = window.innerWidth > 767 ? document.querySelector('.footer') : document.querySelector('.last__item');

            let footerTl = gsap.timeline({
                scrollTrigger: {
                    trigger: footerEl,
                    scroller: ".layout__scroll",
                    start: 'top-=50%',
                    onUpdate: self => {

                        $('.header').addClass('is__hidden');

                        if (self.progress === 1 | self.progress === 0) {
                            $('.header').removeClass('is__hidden');
                        }

                    }
                }
            });

            footerTl.from('.contact', 1, {autoAlpha: 0, y:40, ease: Power1.easeOut}, "start")
                    .staggerFrom('.network li', 1, {autoAlpha:0, y:20, ease: Power1.easeOut}, 0.1, "-=0.4")
                    .staggerFrom('.footer__bottom-legals li', 1, {autoAlpha:0, y:20, ease: Power1.easeOut}, 0.1, "-=0.4")
                    .from('.back__top', 1, {autoAlpha: 0, y:40, ease: Power1.easeOut}, "start")


            if ($('.single-post').length) {

                tlSingleFirst.play();
    
            }

            // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll. 
            ScrollTrigger.addEventListener("refresh", () => {
                locoScroll.update();
                console.log('refresh')
            });

            // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
            ScrollTrigger.refresh();


        });


        /*
		|
		| Slider
		|-----------------
        */
        const swiperTeam = new Swiper('.slider__team', {
            slidesPerView: 1,
            spaceBetween: 30,
            breakpoints: {
                767: {
                    slidesPerView: 2.2,
                    spaceBetween: 30
                }
            },
            navigation: {
                nextEl: '.controls__next',
                prevEl: '.controls__prev',
            }
        })

        /*
		* Cursors
		*/
		const cursorDrag = document.querySelector('.cursor--drag');
		
		let cursorX = -100;
		let cursorY = -100;

		window.addEventListener('mousemove', function(e){
			cursorX = e.clientX;
			cursorY = e.clientY;
		})
		

		/*
		* Cursor drag
		*/
		const dragArea = document.querySelector('.drag--area');
        const slidesDraggable = document.querySelectorAll('.slide--draggable');
        
        if (dragArea) {
            dragArea.addEventListener('mouseover', () => { cursorDrag.classList.add('is--active') });
            dragArea.addEventListener('mouseleave', () => { cursorDrag.classList.remove('is--active') });

            dragArea.addEventListener('mouseup', function(){
                cursorDrag.classList.remove('is--up');
                
                slidesDraggable.forEach(item => {
                    item.classList.remove('is--dragged');
                });
            });

            dragArea.addEventListener('mousedown', function(){
                cursorDrag.classList.add('is--up');

                slidesDraggable.forEach(item => {
                    item.classList.add('is--dragged');
                });
            });

            /*
            * Render
            */
            const render = function() {

                cursorDrag.style.transform = `translate(${cursorX - (dragArea.offsetLeft + 60)}px, ${cursorY - (offsetDrag + 60)}px)`;
        
                requestAnimationFrame(render);
        
            };
        
            requestAnimationFrame(render);
        }

        
        /*
        * NEWS
        */

        $('.article__sticky a').on('mouseover', function(){
            $('.article__sticky-inner').addClass('is-animated');
        })

        $('.article__sticky a').on('mouseleave', function(){
            $('.article__sticky-inner').removeClass('is-animated');
        })

        /*
        |
        | Constants
        |------------
        */
        const
            $loadMoreBtn = $('.load-more-btn'),
            $postsContainer = $('.list__container'),
            $loadMoreContainer = $('.load-more-container'),
            $loadMore = $('.load-more'),
            $loaderContainer = $('.loader-container')
        ;

        let xhr = null;

        /*
        |
        | Load more ajax
        |--------------
        */
        $loadMoreBtn.on('click', function(e){

            e.preventDefault();
            
            abort(xhr);

            let offset = $postsContainer.find('.article').length;
            let url = `/ajax/posts/${offset}/`;

            xhr = $.ajax({
                url: url,
                type: 'GET',
                method: 'GET',
                dataType: 'json',
                beforeSend: () => {
                    gsap.to($loadMore, 0.5, {autoAlpha: 0, y:30, display: "none", ease: Power1.easeOut, onComplete: () => {
                        gsap.fromTo($loaderContainer, 0.5, {autoAlpha: 0, y:30, display: "none"}, {autoAlpha: 1, y:0, display: "block", ease: Power1.easeOut})
                    }})
                },
                success: (response, status) => {

                    $postsContainer.append(response);

                    gsap.to($loaderContainer, 0.5, {autoAlpha: 0, y:50, display: "none", ease: Power1.easeOut});

                    if($(response).find('.no-more-post').length) {
                        $loadMoreContainer.remove();
                    } else {
                        gsap.to($loadMore, 0.5, {autoAlpha: 1, y:0, display: "block", ease: Power1.easeOut})
                    }

                    xhr = null;

                    locoScroll.update();
                },
                error: (response, status, error) => {
                    console.log(error);
                }
            });
            
        });

        /*
        | HELPER
        |
        | abort ajax request
        |------------
        */
        function abort(xhrVar, $list = null){
            if (xhrVar !== null) {
                xhrVar.abort();
            }

            if($list !== null){
                $list.find('.loading--ajax').hide();
            }
        }

        /*
		|
		| Cookie Manager
		|-----------------
		*/
        new CookieManager($cookieBanner, {
            name: 'fibonacci_cookie_use',
            duration: 30,
            buttons: {
                accept: $cookieAccept,
                refuse: $cookieRefuse,
                close: $cookieClose
            },
            onAccept: () => {
                console.log('accpeted')
            },
            onRefuse: () => {
                console.log('refused')
            }
        });


        /*
        * FAKE AJAX
        */
        
        const transitionTl = new gsap.timeline({paused: true});
        transitionTl.to('.transition__layer', 1.2, {scale: 1, ease: Expo.easeInOut}, "start")

        $('.transition__link').on('click', function(event){
            event.preventDefault();
            var href = this.href;

            console.log(href);
            
            transitionTl.play();
            
            transitionTl.eventCallback("onComplete", function(){
                window.location = href;
            });

        });

	}
}

